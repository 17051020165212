import React from "react";
import {graphql, Link} from "gatsby";
import get from "lodash/get";
import {Helmet} from "react-helmet";
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout";
import {LGGHeader, MDHeader} from "../components/typography";


const CandidateCard = ({slug, name, position, cycleRegion}) => (
  <>
    <Link to={`/elections/${cycleRegion.cycleSlug}/${slug}`}>
      <div  className="hover:text-black hover:bg-white p-8">
        <h2 className={`font-button text-lg uppercase tracking-wide pb-4`}>
          {position}
        </h2>
        <MDHeader>{name}</MDHeader>
      </div>
    </Link>
  </>
)

const ElectionPageTemplate = ({data, location}) => {
  const electionLandingPage = get(data, 'contentfulElectionsLandingPage')
  const siteTitle = get(data, 'site.siteMetadata.title')
  const {title, description, candidates} = electionLandingPage
  return (
    <Layout location={location}>
      <Helmet title={`${title} | ${siteTitle}`} />
      {/* not doing px-8  at the top level, because we want to be able to have a hover effect on the candidate buttons
          that doesn't make the text misaligned */}
      <div className="pb-8 md:px-16 md:pb-16 text-white max-w-6xl mx-auto pt-40">
        <LGGHeader className="px-8 leading-extra-tight pb-12 md:pb-16">{title}</LGGHeader>
        <div className="px-8 pb-8 markdown pt-4 use-source-sans-pro md:max-w-3/4">{renderRichText(description)}</div>

        <h2 className="px-8 font-button text-lg uppercase tracking-wide pb-8 text-yellow">Select a candidate to learn more</h2>

        <section className="grid grid-cols-1 md:grid-cols-3 gap-12">{candidates.map((candidate, index) => (
          <CandidateCard {...candidate} key={index}/>
        ))}</section>
      </div>
    </Layout>
  )
}

export default ElectionPageTemplate

export const pageQuery = graphql`
  query ElectionLandingPageById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulElectionsLandingPage(id: { eq: $id }) {
      title
      description {
        raw
      }
      candidates {
        name
        slug
        position
        cycleRegion {
          cycleSlug
        }
      }
    }
  }
`
